
import { Component, Prop, Vue } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { BillingAddress } from "@/models/entities/billing-address.interface";
import { paymentsService } from "@/services/api/payments.service";
import OverlayModule from "@/store/modules/overlay.module";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { ExpirationSubscription } from "@/models/entities/expiration-subscription.interface";
import { userService } from "@/services/api/user.service";
import { Downgrade } from "@/models/entities/downgrade.interface";
import { UpgradePayment } from "@/models/entities/upgrade-payment";
import { UpgradeSubYear } from "@/models/entities/upgrade-sub-year.interface";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class ConfirmPlan extends Vue {
  @Prop({ required: true, default: null, type: Number })
  private roleId: number;

  @Prop({ required: true, default: null, type: Boolean })
  private month: boolean;
  private required = required;
  private isFormValid = false;
  private expiration = "";
  private duration = 0;
  private downgrading = false;
  private downgradeConfirmation = false;
  private subscriptionExpired = false;
  private newExpiration = "";
  private radioButton = "upgradeNow";

  private billingAddress: BillingAddress = {
    name: "",
    address: "",
    city: "",
    postalCode: "",
    province: "",
    countryID: 0,
    countryDescr: "",
  };

  private promotionPlanOld: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private promotionPlanNew: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    montlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
  };

  private get downgrade(): boolean {
    return this.promotionPlanNew.roleId < this.promotionPlanOld.roleId;
  }

  private get upgradeString(): string {
    var update =
      this.$tc("payments.UpgradeYearSubscription1") +
      "<b>" +
      this.promotionPlanOld.nameOffert +
      "</b>";
    update += this.month
      ? this.$tc("payments.UpgradeMonthSubscription")
      : this.$tc("payments.UpgradeYearSubscription2");
    update +=
      "<b>" +
      this.promotionPlanNew.nameOffert +
      "</b>" +
      this.$tc("payments.UpgradeYearSubscription3") +
      "<b>" +
      this.newExpiration +
      "</b>" +
      ".";
    return update;
  }

  private async created() {
    overlayModule.showOverlay();
    this.promotionPlanOld = (
      await subscriptionPlansService.getById(authModule.profileRoleID)
    ).data;
    overlayModule.showOverlay();
    this.promotionPlanNew = (
      await subscriptionPlansService.getById(this.roleId)
    ).data;
    overlayModule.showOverlay();
    this.expiration = (await subscriptionPlansService.getExpiration()).data;
    overlayModule.showOverlay();
    this.duration = (await userService.subscriptionDuration()).data;
    this.newExpiration = (
      await paymentsService.remainingDaysUp(this.promotionPlanNew.priceYearID)
    ).data;

    var parse = this.expiration.split("/");
    this.subscriptionExpired =
      new Date(parse[1] + "/" + parse[0] + "/" + parse[2]).getTime() <
      new Date().getTime();
  }

  //   private async saveBillingAddress() {
  //     overlayModule.showOverlay();
  //     await paymentsService.updateCustomer(this.userData);
  //     this.$emit("back");
  //   }

  private back() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "overview",
      },
    });
  }

  private backToPayments() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "home",
      },
    });
  }

  private checkOut() {
    if (this.downgrade && this.duration != 0 && !this.subscriptionExpired) {
      this.downgradeConfirmation = true;
    } else {
      this.checkOutUpgrade();
    }
  }

  private async checkOutDowngrade() {
    overlayModule.showOverlay();
    let downgrade: Downgrade = {
      roleId: 0,
      month: "",
    };
    if (this.month) {
      downgrade.roleId = this.promotionPlanNew.roleId;
      downgrade.month = "1";
      await paymentsService.updatePlan(downgrade);
    } else {
      downgrade.roleId = this.promotionPlanNew.roleId;
      downgrade.month = "12";
      await paymentsService.updatePlan(downgrade);
    }

    this.downgradeConfirmation = false;
    this.$router.push({
      name: "confirmationpage",
    });
  }

  private async checkOutUpgrade() {
    overlayModule.showOverlay();
    var billingAddress = (await userService.GetBillingAddressByID()).data;
    if (
      billingAddress.name != "" &&
      billingAddress.address != "" &&
      billingAddress.city != "" &&
      billingAddress.postalCode != "" &&
      billingAddress.province != "" &&
      billingAddress.countryID != 0
    ) {
      var url = "";
      let payment: UpgradePayment = {
        durationMonth: 0,
        priceId: "",
      };
      let upgradeSubYear: UpgradeSubYear = {
        roleId: 0,
        priceId: "",
        durationMonth: 0,
      };
      let upgrade: Downgrade = {
        roleId: 0,
        month: "",
      };
      if (this.month) {
        if (this.duration != 12) {
          if (this.radioButton == "upgradeNow" || this.duration == 3) {
            payment.durationMonth = 1;
            payment.priceId = this.promotionPlanNew.priceMonthID;
            url = (await paymentsService.createSession(payment)).data;
            window.open(url, "_self");
          } else {
            upgrade.roleId = this.promotionPlanNew.roleId;
            upgrade.month = "1";
            await paymentsService.updatePlan(upgrade);
            this.$router.push({
              name: "confirmationpage",
            });
          }
        } else {
          upgradeSubYear.roleId = this.promotionPlanNew.roleId;
          upgradeSubYear.priceId = this.promotionPlanNew.priceMonthID;
          upgradeSubYear.durationMonth = 1;
          await paymentsService.updateSubscriptionYear(upgradeSubYear);
          this.$router.push({
            name: "confirmationpage",
            params: { upgradeYear: "1" },
          });
        }
      } else {
        if (this.duration == 12 && !this.month && !this.downgrade) {
          upgradeSubYear.roleId = this.promotionPlanNew.roleId;
          upgradeSubYear.priceId = this.promotionPlanNew.priceYearID;
          upgradeSubYear.durationMonth = 12;
          await paymentsService.updateSubscriptionYear(upgradeSubYear);
          this.$router.push({
            name: "confirmationpage",
            params: { upgradeYear: "1" },
          });
        } else {
          payment.durationMonth = 12;
          payment.priceId = this.promotionPlanNew.priceYearID;
          url = (await paymentsService.createSession(payment)).data;
          window.open(url, "_self");
        }
      }
    } else {
      this.$emit("previousPage", this.roleId);
      this.$router.push({
        name: "account",
        params: {
          context: "payments",
          payments: "billingaddress",
        },
      });
    }
  }
}
